<template>
  <div class="spinner-container" v-show="loaderVisible">
    <div class='spinner-loader'>
      <div class="blue">
      </div>
      <div class="white">
      </div>
      <div class="orange">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: ['globalData'],
  computed: {
    loaderVisible : function(){
      return this.globalData.loader.status
    }
  }
}
</script>
<style scoped>
.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color:rgba(0, 0, 0, 0.5);
}
.spinner-loader {
  width: 7em;
  height: 7em;
  position: relative;
  border-radius: 1em;
}
.spinner-loader .blue {
  background-color: #06ABCE;
  width: 5em;
  height: 5em;
  left: 1em;
  top: 1em;
  border-radius: 1em;
  transform: rotate(45deg);
  position: absolute;
  animation: rotate-blue 1s infinite;
  animation-timing-function: ease-in-out;
}
.spinner-loader .white {
  background-color: white;
  width: 4.5em;
  height: 4.5em;
  position: absolute;
  left: 1.25em;
  top: 1.25em;
  border-radius: 1em;
  transform: rotate(65deg);
  animation: rotate-white 1s infinite;
  animation-timing-function: ease-in-out;
}
.spinner-loader .orange {
  width: 3.25em;
  height: 3.25em;
  background-color: #FCB925;
  position: absolute;
  left: 1.875em;
  top: 1.875em;
  transform: rotate(77.5deg);
  border-radius: 0.5em;
  animation: rotate-orange 1s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes rotate-blue {
  0% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(405deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes rotate-white {
  0% {
    transform: rotate(65deg);
  }
  20% {
    transform: rotate(65deg);
  }
  80% {
    transform: rotate(425deg);
  }
  100% {
    transform: rotate(425deg);
  }
}
@keyframes rotate-orange {
  0% {
    transform: rotate(77.5deg);
  }
  40% {
    transform: rotate(77.5deg);
  }
  100% {
    transform: rotate(437.5deg);
  }
}
</style>