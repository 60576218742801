<template>
  <header class="header">
    <nav class="navbar fixed-top navbar-expand-lg navbar-light">
      <div class="container">
        <router-link class="logo " :to="{name : 'Page_234'}">
              <img src="../assets/alteo_logo.png" alt="ALTEO logó">
        </router-link>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#headerNavbar" aria-controls="headerNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse justify-content-between collapse" id="headerNavbar">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_235'}">{{headerContents.menuItem1}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_236'}">{{headerContents.menuItem2}}</router-link>
            </li>
            <li class="nav-item">
               <a class="nav-link" href="https://alteo.hu/" target="_blank" rel="noopener">{{headerContents.menuItem3}}</a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_237'}">{{headerContents.menuItem4}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_238'}">{{headerContents.menuItem5}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_239'}">{{headerContents.menuItem6}}</router-link>
            </li>
            <li class="nav-item">
              <a class="btn btn-primary" href="https://edu-v2.ecosim.hu/#login" target="_blank" rel="noopener">{{headerContents.btn1}}</a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a href="https://myaccount.ecosim.hu/versenyek/alteo_energy_challenge_2022" target="_blank" rel="noopener" class="btn btn-secondary">{{headerContents.btn2}}</a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

export default{
  name:'Header',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][234]
    const headerContents = contents.header
    return{
      headerContents
    }
  }
}
</script>

<style scoped>
.header{
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 3.333rem;
  z-index: 10;
}
nav:after {
    content:"";
    background: linear-gradient(90deg, #00ACC7 0%, #B7C72A 50.39%, #F5A400 100%);
    display: block;
    height:8px;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.navbar-light .navbar-nav .nav-link{
  color: #4C5359;
}
.nav-item{
  align-self: center;
}
.navbar{
  padding-top: 1.142rem;
  padding-bottom: 1.714rem;
  background-color: #FFFFFF;
}
.btn-secondary{
  margin-left: .5rem;
}
.btn-primary{
  margin-left: 2.857rem;
  margin-right: .786rem;
}
</style>