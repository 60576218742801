<template>
  <footer>
    <div class="container">
        <div class="d-flex footer-menu">
            <ul class="list-inline mx-auto nav">
                <li class="nav-item">
                    <router-link :to="{name : 'Page_240'}">{{footerContents.menuItem1}}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name : 'Page_239'}">{{footerContents.menuItem2}}</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name : 'Page_241'}">{{footerContents.menuItem3}}</router-link>
                </li>
            </ul>
        </div>
    </div>
</footer>
</template>

<script>

export default{
  name:'Footer',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][234]
    const footerContents = contents.footer
    return{
      footerContents
    }
  }
}
</script>

<style scoped>
footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #4C5359;
    text-align: center;
}
footer .container{
    margin: auto;
    padding:35px;
}
.nav-item a{
    color: #FFFFFF;
    text-decoration: none;
    padding: 8px;
}
@media (max-width: 576px){
    .list-inline{
        flex-flow: column;
    }
}
</style>