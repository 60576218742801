<template>
  <section id="cover">
    <div class="container">
      <h1>{{coverContents.txt}}</h1>
<!--      <a href="https://myaccount.ecosim.hu/versenyek/alteo_energy_challenge_2022" target="_blank" rel="noopener" class="btn btn-primary">{{coverContents.btn}}</a>-->
      <a href="https://edu-v2.ecosim.hu/#login" target="_blank" rel="noopener" class="btn btn-primary">{{coverContents.btn2}}</a>
    </div>
  </section>
  <section id="competition" class="container">
    <div class="row">
      <div class="col-md-6 text-content"> 
        <h2>{{competitionContents.title}}</h2>
        <div v-html="competitionContents.txt"></div>
        <router-link class="btn btn-info" :to="{name : 'Page_235'}">{{competitionContents.btn}}</router-link>
      </div>
      <div class="col-md-6">
        <div class="image-holder">
          <img src="../assets/competition.png" alt="">
        </div>
      </div>
    </div>
  </section>
  <section id="prizes">
    <div class="container">
      <h2>{{prizesContents.title}}</h2>
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="prize-value"> 
            <h2>{{prizesContents.prize1}}</h2>
          </div>
          <p>{{prizesContents.txt1}}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="prize-value">
            <h2>{{prizesContents.prize2}}</h2>
          </div>
          <p>{{prizesContents.txt2}}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="prize-value">
            <h2>{{prizesContents.prize3}}</h2>
          </div>
          <p>{{prizesContents.txt3}}</p>
        </div>
      </div>
    </div>
  </section>
  <section id="timing">
    <div class="container">
      <h2>{{timingContents.title}}</h2>
      <div class="row">
        <div class="col timing-box">
          <p>{{timingContents.txt1}}</p>
          <h3>{{timingContents.deadline1}}</h3>
        </div>
        <div class="col timing-box">
          <p>{{timingContents.txt2}}</p>
          <h3>{{timingContents.deadline2}}</h3>
        </div>
        <div class="col timing-box">
          <p>{{timingContents.txt3}}</p>
          <h3>{{timingContents.deadline3}}.</h3>
        </div>
        <div class="col timing-box">
          <p>{{timingContents.txt4}}</p>
          <h3>{{timingContents.deadline4}}</h3>
        </div>
      </div>
      <router-link class="btn btn-secondary" :to="{name : 'Page_236'}">{{timingContents.btn}}</router-link>
    </div>
  </section>
  <Partners :globalData="globalData"></Partners>
</template>

<script>
import Partners from '../components/Partners.vue'

export default {
  name: 'MainPage',
  props:['globalData','pageId'],
  components: {
   Partners
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head
    const timingContents = contents.timing
    const prizesContents = contents.prizes
    const competitionContents = contents.competition
    const coverContents = contents.cover

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description

    return{
      timingContents,
      prizesContents,
      competitionContents,
      coverContents
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  }
}
</script>
<style scoped>
#cover{
  background: url('../assets/main_cover.png') center center no-repeat;
  background-size: cover;
  height: 42.857rem;
  position: relative;
  margin-bottom: 8.571rem;
}
.shape{
  height: 391px;
  width: 387px;
  background: linear-gradient(0deg, #F7A600 0%, #F4A800 12%, #EAAD02 22%, #D9B505 31%, #C1C108 40%, #AFCA0B 45%, #AAC910 47%, #00AFCB 100%);
}
#cover .container{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
#cover h1{
  max-width: 38rem; 
  color:#FFFFFF;
  font-weight: normal;
  font-size: 2.856rem;
  line-height: 3.429rem;
  margin-bottom: 3rem;
}
#competition{
  margin-bottom: 8.57rem;
}
#competition .text-content{
  padding-top: 5.285rem;
  padding-left: 7.57rem;
}
#competition .col-md-6:nth-child(2){
  padding-left: 7.57rem;
  padding-right: 7.57rem;
  position: relative;
}
#competition h2{
  font-size: 2.857rem;
  line-height: 3.428rem;
  color: #E3A716;
  margin-bottom: 1.714rem;
}
#competition .btn-info{
  margin-top: 2.14rem;
}
#competition img{
  width: 100%;
}
#prizes{
  background-color: #F7F7F7;
  padding-top: 9.214rem;
  padding-bottom: 11.928rem;
  margin-bottom: 8.571rem;
}
#prizes h2{
  text-align: center;
  color: #52ACC4;
  font-size: 2.857rem;
  line-height: 3.428rem;
  margin-bottom: 4.417rem;
}
#prizes .prize-value{
  padding-top: 9rem;
  padding-bottom: 9rem;
   background: linear-gradient(180deg, #52ACC4 0%, #E4B002 100%);
   margin-bottom: 2.857rem;
}
#prizes .prize-value h2{
  color: #FFFFFF;
  margin-bottom:0;
}
#prizes p{
  text-align: center;
  font-size:1.714rem;
  line-height: 2.357rem;
  font-family: 'Open Sans';
}
#timing{
  margin-bottom: 8.571rem;
}
#timing .container{
  padding-left: 5.714rem;
}
#timing h2{
  margin-bottom: 4.714rem;
  color: #B7C72A;
  font-size: 2.857rem;
  line-height: 3.428rem;
}
#timing .col{
  max-width: fit-content;
  padding-right: 5.1rem;
  white-space: nowrap;
}
#timing .timing-box p{
  margin-bottom:0;
}
#timing h3{
  margin-bottom: 2.285rem;
  color: #B7C72A;
  font-weight: bold;
  font-size: 1.714rem;
  line-height: 3.428rem;
}
@media (max-width: 1024px){ 
  #timing .col{
    margin-right: 3rem;
  }
}
@media (max-width: 991px){
  #competition .image-holder{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  #prizes .prize-value{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
@media (max-width: 767px){
  #cover, #competition,#prizes{
    margin-bottom: 5rem;
  }
  #competition .text-content{
    padding-top: 0;
    padding-left: 2rem;
  }
   #competition .image-holder{
     display: none;
   }
   #prizes{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
   #prizes .row{
     place-content: center;
     row-gap: 2rem;
   }
}
@media (max-width: 576px){
  #cover{
    height: 35rem;
  }
  #cover h1{
    font-size: 2rem;
    line-height: 2.5rem;
  }
  #prizes .prize-value{
    padding: 5rem 1rem;
    width: fit-content;
    margin: auto;
  }
}
</style>
