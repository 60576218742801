<template>
  <Loader :globalData="globalData"/>
  <Head :globalData="globalData"></Head>
  <Header :globalData="globalData"/>
  <div class="main-container">
    <router-view :globalData="globalData"/>
  </div>
  <Footer :globalData="globalData"></Footer>
</template>

<script>
import { reactive} from 'vue'
import Loader from "./components/Loader"
import Head from './components/Head.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {CookieAgree} from './classes/cookieAgree.js'

export default {
  name: 'App',
  props: ['serverData'],
  components: {
   Loader,Head,Header,Footer
  },
  data(){
    const globalData = reactive(this.serverData)
    console.log(this.serverData)
    globalData.loader.status = false

    new CookieAgree()

    return {
      globalData
    }
  }
}
</script>

<style>
</style>
