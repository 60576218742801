<template>
    <section id="partners">
        <div class=" container">
            <div class="row">
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/kando.hu" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/kk_logo.jpg" alt="" width="59"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/SchonherzVallalatiKapcsolatok/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/schonherz_logo.png" alt="" width="82"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.inf.u-szeged.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/szte_logo.png" alt="" width="147"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/pszkszakkollegium/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/lamfalussy_logo.png" alt="" width="91"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="http://www.jpksz.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/janus_logo.png" alt="" width="58"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/estiembudapestbme/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/estiem_logo.png" alt="" width="100"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/HantosElemerSzakkollegium/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/hantos_logo.png" alt="" width="70"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/dexmuhely" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/dex_logo.png" alt="" width="82"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="http://fakt.org.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/fakt_logo.png" alt="" width="97"/></a>
                </div>
                <div class="col logo-holder">
                    <a class="position-relative" href="https://www.facebook.com/INFHA/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/infha_logo.png" alt="" width="78"/></a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default{
  name:'Partners',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][234]
    // const partnerContents = contents.partners
    return{
    //   partnerContents
    }
  }
}
</script>

<style scoped>
#partners{
   background-color: #F7F7F7;
   padding-top: 8.571rem;
   padding-bottom: 8.571rem;
}
#partners .col {
    align-self: center;
}
#partners  .row{
    text-align: center;
    row-gap: 1rem;
}
#partners img{
  border-radius: 0;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
@media (max-width: 767px){
    #partners{
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

</style>