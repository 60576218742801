<template>
</template>

<script>

export default{
  name:'Head',
  props:['globalData'],
  data(){
    const title = this.globalData.init.head.title
    const keywords = this.globalData.init.head.keywords
    const description = this.globalData.init.head.description
    document.querySelector('head title').innerHTML = title
    document.querySelector('meta[property="og:title"]').setAttribute('content',title)
    document.querySelector('meta[name="description"]').setAttribute('content',description)
    document.querySelector('meta[property="og:description"]').setAttribute('content',description)
    document.querySelector('meta[name="keywords"]').setAttribute('content',keywords)
    return{}
  },
  computed:{
    headTitle(){
      return `${this.globalData.init.head.title}`
    },
    headKeywords(){
      return `${this.globalData.init.head.keywords}`
    },
    headDescription(){
      return `${this.globalData.init.head.description}`
    }
  },
  watch:{
    headTitle(newValue){
      document.querySelector('head title').innerHTML = newValue
      document.querySelector('meta[property="og:title"]').setAttribute('content',newValue)

    },
    headKeywords(newValue){
      document.querySelector('meta[name="description"]').content = newValue
      document.querySelector('meta[property="og:description"]').setAttribute('content',newValue)
    },
    headDescription(newValue){
      document.querySelector('meta[name="keywords"]').content = newValue
    }
  }
}
</script>

<style scoped>
</style>